<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        ref="users_table_ref"
        :records="users" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="searchFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="can('users:CanExport')"
        @row-clicked="onRowClicked"
        @export="onExportUsers"
      >
        <template #filters>
          <b-row>
            <b-col class="">
              <v-select
                class="filter-select"
                placeholder="Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="statusFilter"
                :options="statusOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => statusFilter = val"
              />
            </b-col>
            <b-col>
              <v-select
                class="filter-select"
                placeholder="Church Region"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="churchRegion"
                :options="churchRegionOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => churchRegion = val"
              />
            </b-col>
            <b-col>
              <v-select
                class="filter-select"
                placeholder="Church Area"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="churchArea"
                :options="churchAreaOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => churchArea = val"
              />
            </b-col>
            <b-col>
              <v-select
                class="filter-select"
                placeholder="Church District"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="churchDistrict"
                :options="churchDistrictOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => churchDistrict = val"
              />
            </b-col>
            <b-col>
              <v-select
                class="filter-select"
                placeholder="Church Assembly"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="churchAssembly"
                :options="churchAssemblyOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => churchAssembly = val"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(avatar)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.avatar.path')"
                :text="sentenceCase(avatarText(`${data.item.full_name}`))"
                :variant="`light-${resolveUserRoleVariant(data.item.user_type)}`"
                :to="{ name: 'admin-user-single', params: { id: data.item._id } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-user-single', params: { id: data.item._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.full_name')) }}
            </b-link>
            <span class="text-muted">{{ data.item.email }}</span><br />
            <span class="text-muted">@{{ data.item.phone }}</span>
          </b-media>
        </template>


        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(data.item.status) }}
          </b-badge>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput
} from 'bootstrap-vue'
import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.debouncedFetchUsersData()
  }, 
  immediate: false
}
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BInputGroupAppend, 
    BFormInput,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    showAddUserButton: {
      type: Boolean,
      default: true
    },
    clientGroupId: {
      type: String,
      default: ""
    }
  },
  data(){
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      statusFilter: null,
      userTypeFilter: 'client',
      searchFilter: "",
      users: [],
      importType: "",
      tableColumns: [
        { key: 'avatar', stickyColumn: true, sortable: false, label: 'User' },
        { key: 'church_region', sortable: false, label: 'Region' },
        { key: 'church_area', sortable: false, label: 'Area' },
        { key: 'church_district', sortable: false, label: 'District' },
        { key: 'church_assembly', sortable: false, label: 'Assembly' },
        { key: 'status', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
      ],
      statusOptions: [
        { label: 'Pending Confirmation', value: 'pending_confirmation' },
        { label: 'Active', value: 'active' },
        { label: 'Blocked', value: 'blocked' },
        { label: 'In-Active', value: 'inactive' },
      ],
      churchRegionOptions: [],
      churchAreaOptions: [],
      churchDistrictOptions: [],
      churchAssemblyOptions: [],
      churchRegion: '',
      churchArea: '',
      churchDistrict: '',
      churchAssembly: '',
      debouncedFetchUsersData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchUsersData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    userTypeFilter: watchHandler,
    searchFilter: watchHandler,
    churchRegion: {
      handler(changed) {
        if (changed) {
          this.fetchChurchAreas()
          .then(() => this.debouncedFetchUsersData())
          .catch(() => {});
        } else {
          this.debouncedFetchUsersData()
        }
      },
      immediate: true,
    },
    churchArea: {
      handler(changed) {
        if (changed) {
          this.fetchChurchDistrict()
          .then(() => this.debouncedFetchUsersData())
          .catch(() => {});
        } else {
          this.debouncedFetchUsersData()
        }
      },
      immediate: true,
    },
    churchDistrict: {
      handler(changed) {
        if (changed) {
          this.fetchChurchAssemblies()
          .then(() => this.debouncedFetchUsersData())
          .catch(() => {});
        } else {
          this.debouncedFetchUsersData()
        }
      },
      immediate: true,
    },
    churchAssembly: {
      handler() {
        this.debouncedFetchUsersData()
      },
      immediate: true,
    },
  },
  created(){
    this.debouncedFetchUsersData = debounce(this.fetchAllData, 500);

    const { 
      status, 
      page = 1, 
      search = "", 
      limit = this.perPage,
      church_region = "",
      church_area = "",
      church_assembly = "",
      church_district = ""
    } = this.$route.query;

    if (church_region) {
      this.churchRegion = church_region
    }

    if (church_area) {
      this.churchArea = church_area
    }

    if (church_district) {
      this.churchDistrict = church_district
    }

    if (church_assembly) {
      this.churchAssembly = church_assembly
    }

    this.fetchChurchRegions();

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.userTypeFilter = 'client';
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
          search: this.searchFilter
        }
        const fields = {
          church_region: this.churchRegion,
          church_assembly: this.churchAssembly,
          church_area: this.churchArea,
          church_district: this.churchDistrict
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(fields)) {
          if (value) {
            query[key] = value
          }
        }
        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchUsers({ ...query, user_type: this.userTypeFilter });
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.users = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportUsers(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          status: this.statusFilter,
          client_group_id: this.client_group_id,
          user_type: this.userTypeFilter
        }

        const response = await this.useJwt().adminService.exportUsers(query);
        FileDownload(response.data, "users-export.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchRegions();
        const { data } = request.data;
        this.churchRegionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        if (!this.churchRegion) {
          this.churchRegion = '';
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAreas(
          this.churchRegion
        );
        const { data } = request.data;
        this.churchAreaOptions = data.map((opt) => ({
          label: opt.name,
          value: opt.name,
        }));

        if (this.churchArea) {
          const option = data.find(d => d.name === this.churchArea)
          if (!option) {
            this.churchArea = ''
          }
        }
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchDistricts(
          this.churchRegion,
          this.churchArea
        );
        const { data } = request.data;
        this.churchDistrictOptions = data.map((opt) => ({
          label: opt.name,
          value: opt.name,
        }));

        if (this.churchDistrict) {
          const option = data.find(d => d.name === this.churchDistrict)
          if (!option) {
            this.churchDistrict = ''
          }
        }
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAssemblies(
          this.churchRegion,
          this.churchArea,
          this.churchDistrict
        );
        const { data } = request.data;
        this.churchAssemblyOptions = data.map((opt) => ({
          label: opt.name,
          value: opt.name,
        }));

        if (this.churchAssembly) {
          const option = data.find(d => d.name === this.churchAssembly)
          if (!option) {
            this.churchAssembly = ''
          }
        }
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'admin-update-user', params: { id: rowItem._id } })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
