<template>
  <div>
    <admin-list-users />
  </div>
</template>

<script>
import AdminListUsers from "@/@core/components/shared/admin/users/ListUsers.vue"

export default {
  components: {
    AdminListUsers
  },
  methods: {}
}
</script>

